import { ACESFilmicToneMapping, sRGBEncoding, WebGLRenderer } from 'three';

function Renderer(...options) {
    const renderer = new WebGLRenderer({ antialias: true, alpha: true });
    renderer.physicallyCorrectLights = true;
    renderer.outputEncoding = sRGBEncoding;
    renderer.toneMapping = ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
    // eslint-disable-next-line unicorn/numeric-separators-style
    // renderer.setClearColor(0x000000, 1);

    renderer.domElement.classList.add('c-canvas');

    renderer.resize = () => {
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setPixelRatio(2);
    };

    return renderer;
}

export { Renderer };
