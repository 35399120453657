import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Component from '../utilities/Component';

gsap.registerPlugin(ScrollTrigger);

const SectionTracking = Component('.js-section', (trigger, index) => {
    const trackSection = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'section_viewed',
            section: index + 1, // dove NUMERO è il numero della sezione
        });
    };

    ScrollTrigger.create({
        trigger,
        scroller: window,
        onEnter: trackSection,
    });
});

export { SectionTracking };
