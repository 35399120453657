import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import Component from '../utilities/Component';

const Title = Component('.js-title-unveil', (element) => {
    const split = new SplitText(element, { type: 'chars' });

    gsap.fromTo(
        split.chars,
        {
            opacity: 0,
        },
        {
            opacity: 1,
            duration: 0.3,
            stagger: { amount: 0.3, from: 'random' },
            ease: 'flicker',
            scrollTrigger: {
                trigger: element,
                start: 'top center',
            },
        }
    );
});

export { Title };
