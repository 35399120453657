import gsap from 'gsap';
import Component from '../utilities/Component';

const MenuDropdown = Component('.js-menu-dropdown', (element) => {
    const buttons = [...element.querySelectorAll('.js-menu-dropdown-item')];

    element.addEventListener('mouseenter', () => {
        gsap.to(buttons, {
            opacity: 1,
            pointerEvents: 'all',
            ease: 'flicker',
            duration: 0.25,
        });
    });

    element.addEventListener('mouseleave', () => {
        gsap.to(buttons, {
            opacity: 0,
            pointerEvents: 'none',
            ease: 'flicker',
            duration: 0.25,
        });
    });
});

export { MenuDropdown };
