import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Component from '../utilities/Component';

const Thermoregulation = Component('.js-heat', (element) => {
    const tl = gsap.timeline({
        paused: true,
        defaults: { duration: 1 },
    });

    ScrollTrigger.create({
        trigger: element,
        start: 'top top',
        end: 'bottom top',
        onEnter: () => {
            tl.play();
        },
    });

    const slider = element.querySelector('.js-heat-slider');
    const sliderButton = element.querySelector('.js-heat-btn');
    const sliderLines = [...slider.querySelectorAll('line')];
    const line = element.querySelector('.js-heat-line');
    const displays = [...element.querySelectorAll('.js-heat-display')];
    const copies = [...element.querySelectorAll('.js-heat-copy')];
    const title = element.querySelector('.js-heat-title');
    const split = new SplitText(title, { type: 'words, chars' });

    tl.fromTo(line, { transformOrigin: 'left', scaleX: 0 }, { scaleX: 1 }, '<')
        .fromTo(
            sliderButton,
            { opacity: 0 },
            { opacity: 1, duration: 0.5, ease: 'flicker' },
            '<+=.25'
        )
        .fromTo(
            sliderLines,
            { opacity: 0 },
            {
                opacity: 1,
                duration: 0.5,
                stagger: { amount: 0.3, from: 'center' },
                ease: 'flicker',
            },
            '<'
        )
        .fromTo(
            split.chars,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                duration: 0.3,
                stagger: { amount: 0.3, from: 'random' },
                ease: 'flicker',
            },
            '<'
        );

    displays.forEach((display) => {
        const spans = [...display.querySelectorAll('span')];

        spans.forEach((span) => {
            const spanSplit = new SplitText(span, { type: 'words, chars' });

            tl.fromTo(
                spanSplit.chars,
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                    duration: 0.3,
                    stagger: { amount: 0.3, from: 'random' },
                    ease: 'flicker',
                },
                '<'
            );
        });
    });

    copies.forEach((copy) => {
        const copySplit = new SplitText(copy, { type: 'words, chars' });

        tl.fromTo(
            copySplit.chars,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                duration: 0.001,
                stagger: {
                    each: 0.008,
                },
            },
            '<'
        );
    });
});

export { Thermoregulation };
