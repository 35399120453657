import gsap from 'gsap';
import { clamp } from 'lodash-es';
import { Color } from 'three';
import { lerp } from 'three/src/math/MathUtils';
import Component from '../utilities/Component';
import { handle } from '../utilities/virtualizer';
import { dispatcher, EVENTS } from '../webgl/events';
import { emit, on } from '../utilities/bigbro';

const mq = window.matchMedia('(min-width: 768px)');

const HeatSlider = Component('.js-heat-slider', (element) => {
    const input = element.querySelector('.js-heat-slider-input');
    const lines = element.querySelector('.js-heat-slider-lines');
    const fill = element.querySelector('.js-heat-slider-fill');

    const button = element.querySelector('.js-heat-btn');
    const buttonFill = button.querySelector('.c-heat-btn__fill');
    const stroke = button.querySelector('.c-heat-btn__stroke');
    const arrowTop = button.querySelector('.c-heat-btn__arrow-top');
    const arrowBottom = button.querySelector('.c-heat-btn__arrow-bottom');

    const config = {
        pressed: false,
        progress: 0.5,
        lerpedProgress: 0,
        initial: 0,
        btnCurrent: 0,
        btnTarget: 0,
        fillCurrent: 0,
        fillTarget: 0,
    };

    const flickerTl = gsap.timeline({
        paused: true,
        defaults: { ease: 'flicker', duration: 0.25 },
    });

    flickerTl
        .to([buttonFill, arrowTop, arrowBottom], {
            opacity: 0,
        })
        .to([buttonFill, arrowTop, arrowBottom], { opacity: 1, stagger: 0.1 });

    const buttonTimeline = gsap.timeline({
        paused: true,
        defaults: { duration: 0.12 },
    });

    buttonTimeline
        .to(
            buttonFill,
            {
                transformOrigin: 'center center',
                scale: 1.3,
            },
            '<'
        )
        .to(
            arrowTop,
            {
                yPercent: 250,
            },
            '<'
        )
        .to(
            arrowBottom,
            {
                yPercent: -250,
            },
            '<'
        )
        .to(
            stroke,
            {
                strokeDashoffset: '+=.5',
                autoRound: false,
                ease: 'power3.inOut',
            },
            '<'
        );

    const handlePointerDown = () => {
        buttonTimeline.play();
        flickerTl.timeScale(2);
        flickerTl.restart();
    };

    const handlePointerUp = () => {
        buttonTimeline.reverse();
        flickerTl.timeScale(2);
        flickerTl.restart();
    };

    input.addEventListener('mousedown', handlePointerDown);
    input.addEventListener('touchstart', handlePointerDown);

    input.addEventListener('mouseup', handlePointerUp);
    input.addEventListener('touchend', handlePointerUp);

    input.addEventListener('input', () => {
        config.progress = input.value / 100;
    });

    input.addEventListener('change', () => {
        emit(EVENTS.HEAT_CHANGE, input.value, config.progress);
    });

    gsap.ticker.add(() => {
        config.lerpedProgress = lerp(
            config.lerpedProgress,
            config.progress,
            0.1
        );

        if (Math.abs(config.progress - config.lerpedProgress) > 0.001) {
            const inputDimension = input.offsetWidth - button.offsetWidth / 2;

            config.btnCurrent =
                inputDimension * config.lerpedProgress - inputDimension / 2;

            const translateXValue = config.btnCurrent;

            button.style.translate = `${translateXValue}px`;
            fill.style.scale = `${config.lerpedProgress * 2 - 1} 1`;

            emit(
                EVENTS.HEAT_PROGRESS,
                translateXValue,
                config.progress,
                config.lerpedProgress
            );
        }
    });
});

export { HeatSlider };
