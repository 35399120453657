import gsap from 'gsap';
import Component from '../utilities/Component';

const Number = Component('.js-number', (element, elementIndex) => {
    const values = [...element.querySelectorAll('.val')];
    const texts = [...element.querySelectorAll('.js-text')];

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: element,
            start: 'top bottom',
        },
    });

    tl.from(texts, {
        duration: 1,
        scrambleText: {
            text: '',
        },
    })
        .from(texts, { opacity: 0, ease: 'flicker' }, '-=.5')
        .from(
            values,
            {
                y: (index) => (index % 2 ? (elementIndex % 2 ? 100 : -100) : 0),
                x: (index) => (index % 2 ? 0 : elementIndex % 2 ? -100 : 100),
                opacity: 0,
                duration: 0.75,
                ease: 'circ.inOut',
            },
            '<'
        )
        .to(
            values,
            {
                opacity: 0,
                stagger: 0.15,
                duration: 0.25,
                ease: 'flicker',
            },
            '-=.5'
        )
        .to(values, {
            opacity: 1,
            stagger: 0.15,
            duration: 0.25,
            ease: 'flicker',
        });
});

export { Number };
