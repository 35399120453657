import { AnimationMixer, Clock, Group, PointLightHelper } from 'three';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { on } from '../../utilities/bigbro';
import { EVENTS } from '../events';
import { World, camera } from '../world';
import { PointLight } from './PointLight';
import { Shirt } from './Shirt';
import { Lines } from './Lines';

async function Scene() {
    const world = World();
    world.resize();
    world.enable();

    on(EVENTS.RESIZE, () => world.resize());

    camera.position.z = 4;

    const pointLight = PointLight();
    world.addToScene(pointLight);

    const shirtModel = Shirt();
    // world.addToScene(shirt);

    const lines = Lines();
    lines.visible = false;
    // world.addToScene(lines);

    const shirt = new Group();
    shirt.add(shirtModel, lines);
    world.addToScene(shirt);

    const mixer = new AnimationMixer(shirtModel);
    const action = mixer.clipAction(shirtModel.runAnimation);
    action.timeScale = 0.5;

    ScrollTrigger.create({
        trigger: '.c-technology',
        start: 'top top',
        onEnter: () => {
            lines.visible = true;
        },
        onLeaveBack: () => {
            lines.visible = false;
        },
    });

    const mm = gsap.matchMedia();

    // mobile;
    mm.add('(max-width: 1023px)', () => {
        const shirtTimeline = gsap.timeline({
            defaults: { ease: 'linear.easeNone' },
        });

        shirtTimeline
            .fromTo(
                shirt.position,
                {
                    y: 0,
                },
                {
                    y: 0,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-newborn',
                        start: 'top center',
                        end: 'top bottom',
                    },
                }
            )
            .fromTo(
                shirt.rotation,
                {
                    y: 0,
                },
                {
                    y: 0,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-newborn',
                        start: 'top center',
                        end: 'top bottom',
                    },
                }
            )
            .fromTo(
                pointLight,
                {
                    decay: 20,
                    intensity: 0,
                    pos: 0.3,
                },
                {
                    decay: 1,
                    intensity: 25,
                    pos: 2,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-newborn',
                        endTrigger: '.c-newborn__numbers',
                        start: 'center bottom',
                        end: 'top top',
                        onUpdate: () => {
                            pointLight.position.x =
                                Math.sin(Math.PI * pointLight.pos) * 0.8;
                            pointLight.position.y =
                                Math.cos(Math.PI * pointLight.pos) * 0.8;
                        },
                    },
                }
            )
            .fromTo(
                camera.position,
                { z: 2, y: 0 },
                {
                    z: 1.1,
                    y: 0.1,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-newborn',
                    },
                }
            )
            .fromTo(
                shirt.rotation,
                {
                    y: 0,
                },
                {
                    y: Math.PI * 0.25,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-newborn',
                        endTrigger: '.c-technology',
                        start: 'top top',
                        end: 'top top',
                    },
                }
            )
            .fromTo(
                shirt.position,
                {
                    y: 0,
                },
                {
                    y: 0.2,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-newborn',
                        endTrigger: '.c-technology',
                        start: 'top top',
                        end: 'top top',
                    },
                }
            )
            .fromTo(
                camera.position,
                {
                    y: 0.1,
                },
                {
                    y: 2.25,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-technology',
                        start: 'center center',
                        end: 'center center',
                    },
                }
            )
            .fromTo(
                shirt.rotation,
                {
                    y: Math.PI * 0.25,
                },
                {
                    y: Math.PI * 0.25,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-technology',
                        start: 'center center',
                        end: 'center center',
                    },
                }
            )
            .fromTo(
                camera.position,
                {
                    y: 2.25,
                },
                {
                    y: 0.55,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-breathability__intensity',
                        start: 'top bottom',
                        end: 'top top',
                        onEnter() {
                            action.play();
                        },
                        onLeaveBack() {
                            action.stop();
                        },
                    },
                }
            )
            .fromTo(
                shirt.rotation,
                { y: Math.PI * 0.25 },
                {
                    y: 0,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-thermoregulation',
                        start: 'top bottom',
                        end: 'top top',
                    },
                }
            )
            .fromTo(
                camera.position,
                {
                    y: 0.55,
                    z: 1.1,
                },
                {
                    y: 0.3,
                    z: 1.3,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-thermoregulation',
                        start: 'top bottom',
                        end: 'top top',
                    },
                }
            );
    });

    // desktop;
    mm.add('(min-width: 1024px)', () => {
        const shirtTimeline = gsap.timeline({
            defaults: { ease: 'linear.easeNone' },
        });

        shirtTimeline
            .fromTo(
                pointLight,
                {
                    decay: 20,
                    intensity: 0,
                    pos: 0.3,
                },
                {
                    decay: 1,
                    intensity: 25,
                    pos: 2,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-challenge',
                        endTrigger: '.c-newborn__numbers',
                        start: 'top center',
                        end: 'top bottom',
                        onUpdate: () => {
                            pointLight.position.x =
                                Math.sin(Math.PI * pointLight.pos) * 0.8;
                            pointLight.position.y =
                                Math.cos(Math.PI * pointLight.pos) * 0.8;
                        },
                    },
                }
            )
            .fromTo(
                shirt.position,
                {
                    y: 0,
                },
                {
                    y: 0,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-challenge',
                        start: 'top center',
                        end: 'top bottom',
                    },
                }
            )
            .fromTo(
                shirt.rotation,
                {
                    y: 0,
                },
                {
                    y: 0,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-challenge',
                        start: 'top center',
                        end: 'top bottom',
                    },
                }
            )
            .fromTo(
                camera.position,
                {
                    z: 1,
                    y: 0,
                },
                {
                    z: 0.6,
                    y: 0.4,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-challenge',
                        endTrigger: '.c-newborn__numbers',
                        start: 'top center',
                        end: 'top bottom',
                    },
                }
            )
            .fromTo(
                shirt.rotation,
                {
                    y: 0,
                },
                {
                    y: Math.PI * 0.25,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-challenge',
                        endTrigger: '.c-technology',
                        start: 'top top',
                        end: 'top top',
                    },
                }
            )
            .fromTo(
                shirt.position,
                {
                    y: 0,
                },
                {
                    y: 0.5,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-challenge',
                        endTrigger: '.c-technology',
                        start: 'top top',
                        end: 'top top',
                    },
                }
            )
            // breathability
            .fromTo(
                camera.position,
                { z: 0.6 },
                {
                    y: 0.12,
                    z: 0.65,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-technology',
                        start: 'center top',
                        end: 'center top',
                        onEnter() {
                            action.play();
                        },
                        onLeaveBack() {
                            action.stop();
                        },
                    },
                }
            )
            .fromTo(
                shirt.position,
                { y: 0.3 },
                {
                    y: 0,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-technology',
                        start: 'center top',
                        end: 'center top',
                    },
                }
            )
            .fromTo(
                shirt.rotation,
                { y: Math.PI * 0.25 },
                {
                    y: Math.PI * 0.25,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-technology',
                        start: 'center top',
                        end: 'center top',
                    },
                }
            )
            // thermo
            .fromTo(
                shirt.rotation,
                { y: Math.PI * 0.25 },
                {
                    y: 0,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-thermoregulation',
                        start: 'top bottom',
                        end: 'top top',
                    },
                }
            )
            .fromTo(
                camera.position,
                {
                    y: 0.12,
                    z: 0.65,
                },
                {
                    y: 0,
                    z: 1.1,
                    scrollTrigger: {
                        scrub: 1,
                        trigger: '.c-thermoregulation',
                        start: 'top bottom',
                        end: 'top top',
                    },
                }
            );
    });

    function increaseSpeed() {
        gsap.to(action, {
            timeScale: 1,
            duration: 1,
        });
    }

    function decreaseSpeed() {
        gsap.to(action, {
            timeScale: 0.5,
            duration: 1,
        });
    }

    on(EVENTS.SPEED_INCREASE, increaseSpeed);
    on(EVENTS.SPEED_DECREASE, decreaseSpeed);

    const clock = new Clock();

    gsap.ticker.add((time) => {
        world.render();
        mixer.update(clock.getDelta());
    });
}

export { Scene };
