import gsap from 'gsap';
import Component from '../utilities/Component';
import { emit, on } from '../utilities/bigbro';
import { EVENTS } from '../webgl/events';

const BackgroundSwitch = Component('.js-background-switch', (element) => {
    const grey = element.querySelector('.js-background-grey');
    const temperature = element.querySelector('.js-background-temperature');

    const colorTimeline = gsap.timeline({
        paused: true,
        defaults: { ease: 'linear.easeNone' },
    });

    colorTimeline
        .from(temperature, {
            backgroundColor: '#405378',
            duration: 1,
        })
        .to(temperature, {
            backgroundColor: '#000',
            duration: 0.1,
        })
        .to(temperature, {
            backgroundColor: '#B24242',
            duration: 1,
        });

    const changeBackgroundColor = (_, _2, lerpedProgress) => {
        colorTimeline.progress(lerpedProgress);
    };

    on(EVENTS.HEAT_PROGRESS, changeBackgroundColor);

    gsap.fromTo(
        grey,
        {
            opacity: 1,
        },
        {
            opacity: 0,
            scrollTrigger: {
                scrub: 1,
                trigger: element,
                start: 'center bottom',
                end: 'center top',
                onUpdate: () => {
                    emit(EVENTS.HEAT_ENTER, grey.style.opacity);
                },
                onLeave: () => {
                    emit(EVENTS.HEAT_ENTER, 0);
                },
                onLeaveBack: () => {
                    emit(EVENTS.HEAT_ENTER, 1);
                },
            },
        }
    );
});

export { BackgroundSwitch };
