import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Component from '../utilities/Component';

const Breathability = Component('.js-breath', (element) => {
    const tl = gsap.timeline({
        paused: true,
        defaults: { duration: 1 },
    });

    ScrollTrigger.create({
        trigger: element,
        start: 'top top',
        end: 'bottom top',
        onEnter: () => {
            tl.play();
        },
    });

    const video = element.querySelector('.js-breath-video');
    const line = element.querySelector('.js-breath-line');
    const button = element.querySelector('.js-speed-btn');

    const label = element.querySelector('.js-breath-label');
    const labelSplit = new SplitText(label, { type: 'words, chars' });

    const title = element.querySelector('.js-breath-title');
    const titleSplit = new SplitText(title, { type: 'words, chars' });

    const copy = element.querySelector('.js-breath-copy');
    const copySplit = new SplitText(copy, { type: 'words, chars' });

    tl.fromTo(line, { transformOrigin: 'right', scaleX: 0 }, { scaleX: 1 }, '<')
        .fromTo(
            video,
            {
                clipPath: 'polygon(0 0, 100% 0, 100% 0%, 0 0%)',
            },
            {
                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
                duration: 1.5,
                ease: 'power3.inOut',
            },
            '<'
        )
        .fromTo(
            button,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                duration: 0.3,
                ease: 'flicker',
            },
            '<'
        )
        .fromTo(
            labelSplit.chars,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                duration: 0.3,
                stagger: { amount: 0.3, from: 'random' },
                ease: 'flicker',
            },
            '<'
        )
        .fromTo(
            titleSplit.chars,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                duration: 0.3,
                stagger: { amount: 0.3, from: 'random' },
                ease: 'flicker',
            },
            '<'
        )
        .fromTo(
            copySplit.chars,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                duration: 0.001,
                stagger: {
                    each: 0.008,
                },
            },
            '<'
        );
});

export { Breathability };
