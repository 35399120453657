import gsap from 'gsap';
import { RoughEase } from 'gsap/EasePack';
import { debounce } from 'lodash-es';
import { Howl, Howler } from 'howler';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';
import { Cache, Texture, TextureLoader, sRGBEncoding } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ScrambleTextPlugin } from 'gsap/ScrambleTextPlugin';
import { SplitText } from 'gsap/SplitText';
import Lenis from '@studio-freight/lenis';
import { emit, on } from './utilities/bigbro';
import { EVENTS } from './webgl/events';
import { setVhValue } from './utilities/vh';
import { addLoader, addResources, loadAssets } from './utilities/loader';
import { ASSETS } from './utilities/constants';
import { App } from './App';
import { Loader } from './components/Loader';
import { Button } from './components/Button';
import { Intro } from './components/Intro';
import { Scene } from './webgl/Components/Scene';

Cache.enabled = true;
Howler.autoUnlock = false;

gsap.registerPlugin(
    RoughEase,
    ScrollTrigger,
    ScrambleTextPlugin,
    SplitText,
    TextPlugin
);

gsap.registerEase(
    'flicker',
    // eslint-disable-next-line max-len
    "rough({ template: power0.none, strength: 10, points: 20, taper: 'none', randomize: true, clamp:  true})"
);

gsap.config({ nullTargetWarn: false });

const emitResize = debounce(() => emit(EVENTS.RESIZE));
window.addEventListener('resize', emitResize);

setVhValue();

const loader = Loader();
// const intro = Intro();

(async () => {
    const lenis = new Lenis({
        lerp: 0.07,
    });

    window.addEventListener('beforeunload', () => {
        lenis.scrollTo(0, { immediate: true });
    });

    gsap.ticker.add((time) => {
        lenis.raf(time * 1000);
        emit(EVENTS.RENDER, time);
    });

    lenis.on('scroll', ({ progress }) => {
        ScrollTrigger.update();
        emit(EVENTS.SCROLL_PROGRESS, progress);
    });

    lenis.stop();

    await loader.enter();

    const textureLoader = new TextureLoader();
    const gltfLoader = new GLTFLoader();

    addLoader(
        {
            alias: 'texture',
            loader: textureLoader,
            loadFunction: async (loaderInstance, url) => {
                const image = new Image();
                image.src = url;

                await image.decode();

                const texture = new Texture(image);
                texture.needsUpdate = true;
                texture.flipY = false;
                texture.encoding = sRGBEncoding;

                return texture;
            },
        },
        {
            alias: 'gltf',
            loader: gltfLoader,
            loadFunction: async (loaderInstance, url) => {
                try {
                    return await loaderInstance.loadAsync(url);
                } catch {
                    return false;
                }
            },
        },
        {
            alias: 'sound',
            loader: Howl,
            loadFunction: async (LoaderClass, url) =>
                new LoaderClass({
                    src: [url],
                    preload: true,
                    html5: false,
                }),
        }
    );

    addResources(ASSETS);

    const loadervalues = {
        current: 0,
        actual: 0,
    };

    const handleLoaderUpdate = async (index) => {
        loadervalues.actual = index / ASSETS.length;

        emit(EVENTS.ASSET_UPDATE, loadervalues.actual);

        await gsap.to(loadervalues, {
            current: loadervalues.actual,
            duration: 2,
            onUpdate: () => {
                const value = Math.round(loadervalues.current * 100);
                emit(EVENTS.LOADER_UPDATE, value);
            },
        });
    };

    const handleLoaderComplete = () => {
        emit(EVENTS.LOADER_COMPLETE);
    };

    on(EVENTS.LOADER_COMPLETE, async () => {
        await loader.exit();

        await Scene();

        lenis.start();
        App();
    });

    on(EVENTS.APP_START, async () => {
        lenis.start();
        App();
    });

    Button();

    await loadAssets(
        handleLoaderUpdate,
        handleLoaderUpdate,
        handleLoaderComplete
    );
})();

const matrix = [...document.querySelectorAll('.js-scramble')];

matrix.forEach((m) => {
    gsap.from(m, {
        opacity: 0,
        ease: 'flicker',
        duration: 0.2,
        scrollTrigger: {
            trigger: m,
            start: 'top center',
        },
    });
});

const scrambles = [...document.querySelectorAll('.js-scramble-unveil')];

scrambles.forEach((s) => {
    gsap.from(s, {
        duration: 0.7,
        scrambleText: {
            text: '&nbsp;',
        },
        scrollTrigger: {
            trigger: s,
            start: 'top center',
        },
    });
});
