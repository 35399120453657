import { gsap } from 'gsap';
import Component from '../utilities/Component';

const ParallaxMedia = Component('.js-parallax-media', (element) => {
    const scale = element.querySelector('.js-parallax-scale');
    const media = element.querySelector('.js-parallax-element');

    const tl = gsap.timeline();

    tl.fromTo(
        media,
        { yPercent: -8 },
        {
            yPercent: 8,
            scrollTrigger: {
                trigger: media,
                scrub: 1,
                start: 'top bottom',
                end: 'bottom top',
            },
        },
        '<'
    );

    gsap.from(
        scale,
        {
            scale: 1.3,
            scrollTrigger: {
                trigger: media,
                start: 'top bottom',
            },
        },
        '<'
    );

    gsap.fromTo(
        element,
        {
            clipPath: 'polygon(0 0, 100% 0, 100% 0%, 0 0%)',
        },
        {
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
            duration: 1,
            delay: 0.2,
            ease: 'power3.inOut',
            scrollTrigger: {
                trigger: element,
                start: 'top bottom-=200',
            },
        }
    );
});

export { ParallaxMedia };
