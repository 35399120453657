function Component(selector, callback) {
    return () => {
        const elements = [...document.querySelectorAll(selector)];

        elements.forEach((element, index) => {
            callback(element, index);
        });
    };
}

export default Component;
