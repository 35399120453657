import gsap from 'gsap';
import Component from '../utilities/Component';
import { on } from '../utilities/bigbro';
import { EVENTS } from '../webgl/events';

const ScrollProgress = Component('.js-scroll-progress', (element) => {
    on(EVENTS.SCROLL_PROGRESS, (progress) => {
        gsap.set(element, {
            scaleX: progress,
        });
    });
});

export { ScrollProgress };
