import Component from '../utilities/Component';
import { emit } from '../utilities/bigbro';
import { EVENTS } from '../webgl/events';

const ModalTrigger = Component('.js-modal-trigger', (element) => {
    const {
        dataset: { source = '' },
    } = element;

    element.addEventListener('click', () => {
        emit(EVENTS.OPEN_MODAL, source);
        emit(EVENTS.SOUND_TOGGLE, true);
    });
});

export { ModalTrigger };
