import { UAParser } from 'ua-parser-js';

export const IS_CLIENT = typeof window !== 'undefined';

const PARSER = new UAParser();
PARSER.setUA(navigator.userAgent);

export const BROWSER = PARSER.getBrowser();
export const DEVICE = PARSER.getDevice();

export const ASSETS = [
    {
        name: 'diffuse',
        type: 'texture',
        url: 'shirt/diffuse.png',
    },
    {
        name: 'bump',
        type: 'texture',
        url: 'shirt/bump.png',
    },
    {
        name: 'normal',
        type: 'texture',
        url: 'shirt/normal.png',
    },
    {
        name: 'heat red',
        type: 'texture',
        url: 'shirt/heat-red.png',
    },
    {
        name: 'shirt',
        type: 'gltf',
        url: 'shirt/shirt.glb',
    },
    {
        name: 'vol',
        type: 'sound',
        url: 'sound.mp3',
    },
];
