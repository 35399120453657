import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import { on } from '../utilities/bigbro';
import { EVENTS } from '../webgl/events';

const Loader = () => {
    const element = document.querySelector('.js-loader');
    const texts = [...element.querySelectorAll('.js-loader-text')];
    const numbers = [...element.querySelectorAll('.js-loader-number')];
    const [fill, stroke] = [...element.querySelectorAll('.js-loader-logo svg')];

    const enter = () => {
        const timeline = gsap.timeline({ defaults: { duration: 0.75 } });

        timeline
            .set(element, { opacity: 1 })
            .from(numbers, {
                xPercent: (index) => (index ? -400 : 400),
                ease: 'Power2.easeInOut',
            })
            .from(stroke, { scale: 0.8, ease: 'Power2.easeInOut' }, '<')
            .from(stroke, { opacity: 0, ease: 'flicker' }, '<');

        numbers.forEach((number) => {
            const split = new SplitText(number, { type: 'chars' });

            timeline.from(
                split.chars,
                {
                    opacity: 0,
                    stagger: { amount: 0.2, from: 'random' },
                    ease: 'flicker',
                },
                '<'
            );
        });

        texts.forEach((text, textIndex) => {
            const split = new SplitText(text, {
                type: 'lines,chars',
                tag: 'span',
            });

            timeline.fromTo(
                split.chars,
                { opacity: 0 },
                {
                    opacity: 1,
                    duration: 0.3,
                    stagger: { amount: 0.3, from: 'random' },
                    ease: 'flicker',
                },
                '<'
            );

            timeline.fromTo(
                split.lines,
                {
                    xPercent: textIndex ? 10 : -10,
                },
                {
                    xPercent: 0,
                    duration: 0.3,
                    stagger: { amount: 0.2, from: 'random' },
                },
                '<'
            );
        });

        return timeline;
    };

    const exit = () => {
        const timeline = gsap.timeline();

        timeline
            .to([fill, stroke, ...numbers, ...texts], {
                opacity: 0,
                duration: 0.25,
                stagger: { amount: 0.2, from: 'random' },
                ease: 'flicker',
            })
            .set(element, { opacity: 0, pointerEvents: 'none' });

        return timeline;
    };

    on(EVENTS.LOADER_UPDATE, (progress) => {
        numbers.forEach((number) => {
            gsap.to(fill, {
                clipPath: `polygon(0 ${100 - progress}%, 100% ${
                    100 - progress
                }%, 100% 100%, 0% 100%)`,
            });

            number.textContent = progress.toString().padStart(3, '0');
        });
    });

    return {
        enter,
        exit,
    };
};

export { Loader };
