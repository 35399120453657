import { AxesHelper, Color, GridHelper, Scene } from 'three';
import { Camera } from './camera';
import { Renderer } from './renderer';
import { dispatcher, EVENTS } from './events';

const camera = Camera();
const renderer = Renderer();
const scene = new Scene();
// scene.background = new Color(0x30_30_30);

function World() {
    // const axesHelper = new AxesHelper(10);
    // scene.add(axesHelper);

    // const gridHelper = new GridHelper(10);
    // scene.add(gridHelper);

    return {
        enable: () => {
            document.querySelector('.l-page').append(renderer.domElement);
            dispatcher.dispatchEvent(EVENTS.ENABLE);
        },
        render: () => {
            dispatcher.dispatchEvent(EVENTS.RENDER);
            renderer.render(scene, camera);
            dispatcher.dispatchEvent(EVENTS.AFTER_RENDER);
        },
        resize: () => {
            camera.resize();
            renderer.resize();
        },
        addToScene: (...elements) => {
            scene.add(...elements);
        },
        removeFromScene: (...elements) => {
            scene.remove(...elements);
        },
    };
}

export { World, camera, renderer, scene };
