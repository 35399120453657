import { PointLight as Light } from 'three';

function PointLight() {
    const pointLight = new Light(0xff_ff_ff, 18);
    pointLight.pos = 0.5;
    pointLight.position.x = Math.sin(Math.PI * pointLight.pos) * 0.8;
    pointLight.position.y = Math.cos(Math.PI * pointLight.pos) * 0.8;
    pointLight.position.z = 0.8;
    pointLight.decay = 25;

    return pointLight;
}

export { PointLight };
