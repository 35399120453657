import gsap from 'gsap';
import Component from '../utilities/Component';
import { on } from '../utilities/bigbro';
import { EVENTS } from '../webgl/events';

const TemperatureDisplay = Component('.js-temperature-display', (element) => {
    const value = element.querySelector('.js-temperature-value');

    const handleHeatProgress = (y) => {
        // gsap.set(element, { y });
    };

    const handleHeatChange = (value_) => {
        const range = gsap.utils.mapRange(0, 100, -10, 20, value_);
        const text = `${range > 0 ? '+' : ''}${Number.parseFloat(
            range.toFixed(0)
        )}° C`;

        gsap.to(value, {
            scrambleText: {
                text,
            },
        });
    };

    on(EVENTS.HEAT_PROGRESS, handleHeatProgress);
    on(EVENTS.HEAT_CHANGE, handleHeatChange);
});

export { TemperatureDisplay };
