import {
    Color,
    LinearEncoding,
    LinearSRGBColorSpace,
    MeshBasicMaterial,
    MeshPhysicalMaterial,
    MeshStandardMaterial,
    SRGBColorSpace,
    sRGBEncoding,
    TextureLoader,
    Vector2,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { getAsset } from '../../utilities/loader';
import { EVENTS } from '../events';
import { on } from '../../utilities/bigbro';
import { gsap } from 'gsap';

const loader = new TextureLoader();
const gltfLoader = new GLTFLoader();

function Shirt() {
    // const map = await loader.loadAsync('shirt/diffuse.png');
    // const normalMap = await loader.loadAsync('shirt/normal.png');
    // const bumpMap = await loader.loadAsync('shirt/bump.png');
    // const element = await gltfLoader.loadAsync('shirt/shirt.glb');

    const [map, normalMap, bumpMap, element, heatRed] = getAsset(
        'diffuse',
        'normal',
        'bump',
        'shirt',
        'heat red'
    );

    normalMap.encoding = LinearEncoding;
    bumpMap.encoding = LinearEncoding;

    const {
        animations: [runAnimation],
        scene,
    } = element;

    const material = new MeshStandardMaterial({
        transparent: true,
        roughness: 1,
        metalness: 0.9,
        map,
        normalMap,
        normalScale: new Vector2(0.6),
        bumpMap,
    });

    material.onBeforeCompile = (shader) => {
        const blueColor = new Color();
        blueColor.setHex(0x78_b3_de, LinearSRGBColorSpace);
        blueColor.convertSRGBToLinear();

        shader.uniforms.u_time = { value: 0 };
        shader.uniforms.u_heatRed = { value: heatRed };
        shader.uniforms.u_linearProgress = { value: 0 };
        shader.uniforms.u_progress = { value: 0 };
        shader.uniforms.u_heatEnter = { value: 0 };
        shader.uniforms.u_red = { value: new Color('red') };
        shader.uniforms.u_blue = { value: blueColor };

        shader.fragmentShader = `
        uniform sampler2D u_heatRed;
        uniform float u_time;
        uniform float u_progress;
        uniform float u_linearProgress;
        uniform float u_heatEnter;
        uniform vec3 u_red;
        uniform vec3 u_blue;

        ${shader.fragmentShader}`;
        shader.fragmentShader = shader.fragmentShader.replace(
            '#include <output_fragment>',
            `
            #include <output_fragment>
            
            vec4 heatDiffuse = texture2D(u_heatRed, vUv);
            float alpha = heatDiffuse.r;
            alpha = smoothstep(alpha, alpha - .85, 1. - u_progress);
            vec3 color = mix(u_blue, u_red, step(u_linearProgress, .5)) * ((sin(u_time * 4.) * .5 + .5) * (1. - u_heatEnter));
            vec3 diffuse = mix(outgoingLight, color, alpha);

            gl_FragColor = vec4( diffuse, diffuseColor.a );
            `
        );

        on(EVENTS.HEAT_ENTER, (progress) => {
            shader.uniforms.u_heatEnter.value = progress;
        });

        on(EVENTS.HEAT_PROGRESS, (_, _2, progress) => {
            shader.uniforms.u_linearProgress.value = progress;
            shader.uniforms.u_progress.value = gsap.utils.mapRange(
                -1,
                1,
                -0.25,
                0.25,
                Math.abs(progress * 2 - 1)
            );
        });

        on(EVENTS.RENDER, (time) => {
            shader.uniforms.u_time.value = time;
        });
    };

    scene.traverse((child) => {
        if (child.name !== 'TSHIRT_4001') return;

        // eslint-disable-next-line no-param-reassign
        child.material = material;
    });

    scene.loop = () => {};

    scene.runAnimation = runAnimation;

    return scene;
}

export { Shirt };
