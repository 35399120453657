import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import Component from '../utilities/Component';

const Copy = Component('.js-copy-unveil', (element) => {
    const split = new SplitText(element, { type: 'words, chars' });

    gsap.fromTo(
        split.chars,
        {
            opacity: 0,
        },
        {
            opacity: 1,
            duration: 0.001,
            stagger: {
                each: 0.008,
            },
            scrollTrigger: {
                trigger: element,
                start: 'top bottom',
            },
        }
    );
});

export { Copy };
