import gsap from 'gsap';
import Component from '../utilities/Component';
import { emit, on } from '../utilities/bigbro';
import { EVENTS } from '../webgl/events';
import { getAsset } from '../utilities/loader';

const scrambleText = (text) => {
    gsap.from(text, { scrambleText: {}, duration: 0.25 });
};

const SoundToggle = Component('.js-sound-toggle', (element) => {
    const audio = getAsset('vol');

    const onText = element.querySelector('.js-sound-on-text');
    const offText = element.querySelector('.js-sound-off-text');
    const lines = [...element.querySelectorAll('.js-sound-line')];
    const linesTimeline = gsap.timeline();

    element.addEventListener('click', () => {
        emit(EVENTS.SOUND_TOGGLE, element.classList.contains('is-active'));
    });

    const handleSoundToggle = (shouldPause) => {
        if (shouldPause) {
            element.classList.remove('is-active');

            if (offText) {
                scrambleText(offText);
            }

            linesTimeline.clear();
            gsap.to(lines, {
                scaleY: 0.2,
                duration: 0.25,
            });

            audio.pause();
            return;
        }

        element.classList.add('is-active');

        audio.play();

        if (onText) {
            scrambleText(onText);
        }

        linesTimeline.fromTo(
            lines,
            {
                scaleY: 0.2,
            },
            {
                scaleY: 1,
                stagger: {
                    amount: 0.5,
                    from: 'random',
                    repeat: -1,
                    yoyo: true,
                },
            }
        );
    };

    on(EVENTS.SOUND_TOGGLE, handleSoundToggle);
});

export { SoundToggle };
