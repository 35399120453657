import { gsap } from 'gsap';
import Component from '../utilities/Component';
import { emit, on } from '../utilities/bigbro';
import { EVENTS } from '../webgl/events';

const VideoModal = Component('.js-video-modal', (element) => {
    const bg = element.querySelector('.js-video-modal-bg');
    const close = element.querySelector('.js-video-modal-close');
    const iframe = element.querySelector('iframe');

    const tl = gsap.timeline();

    on(EVENTS.OPEN_MODAL, (id) => {
        tl.clear()
            .add(() => {
                iframe.src = `https://www.youtube.com/embed/${id}?autoplay=1&rel=0&controls=0&showinfo=0`;
            })
            .set(element, {
                display: 'grid',
                autoAlpha: 1,
                pointerEvents: 'all',
            })
            .from(bg, { autoAlpha: 0 })
            .from(
                iframe,
                {
                    y: 50,
                    autoAlpha: 0,
                },
                '<+=.25'
            )
            .from(
                close,
                {
                    autoAlpha: 0,
                },
                '<'
            );
    });

    [bg, close].forEach((closer) => {
        closer?.addEventListener('click', () => {
            tl.clear()
                .to(element, { autoAlpha: 0, pointerEvents: 'none' })
                .add(() => {
                    iframe.src = '';
                })
                .set(element, { display: 'none' });
        });
    });
});

export { VideoModal };
