import gsap from 'gsap';
import { RoughEase } from 'gsap/EasePack';
import { debounce, throttle, words } from 'lodash-es';

import Lenis from '@studio-freight/lenis';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {
    AnimationMixer,
    Clock,
    PointLightHelper,
    TextureLoader,
    sRGBEncoding,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ScrambleTextPlugin } from 'gsap/ScrambleTextPlugin';
import { SplitText } from 'gsap/SplitText';
import ScrambleText from 'scramble-text';
import { VideoModal } from './components/VideoModal';
import { createSvg } from './utilities/tech-svg';
import { camera, renderer, scene, World } from './webgl/world';
import { Shirt } from './webgl/Components/Shirt';
import { HeatSlider } from './components/HeatSlider';
import { enable, handle } from './utilities/virtualizer';
import { SpeedButton } from './components/SpeedButton';
import { Title } from './components/Title';
import { Number } from './components/Number';
import { emit, inspect, on } from './utilities/bigbro';
import { EVENTS } from './webgl/events';
import { LanguageSelector } from './components/LanguageSelector';
import { ScrollProgress } from './components/ScrollProgress';
import { SoundToggle } from './components/SoundToggle';
import { setVhValue } from './utilities/vh';
import { TemperatureDisplay } from './components/TemperatureDisplay';
import { BackgroundSwitch } from './components/BackgroundSwitch';
import { addLoader, addResources, loadAssets } from './utilities/loader';
import { ASSETS } from './utilities/constants';
import { Hero } from './components/Hero';
import { PointLight } from './webgl/Components/PointLight';
import { Scene } from './webgl/Components/Scene';
import { ParallaxMedia } from './components/ParallaxMedia';
import { Thermoregulation } from './components/Thermoregulation';
import { Breathability } from './components/Breathability';
import { SoftTouch } from './components/SoftTouch';
import { Copy } from './components/Copy';
import { ModalTrigger } from './components/ModalTrigger';
import { SectionTracking } from './components/SectionTracking';

const App = async () => {
    enable();
    ParallaxMedia();
    HeatSlider();
    SpeedButton();
    Title();
    Copy();
    Number();
    ScrollProgress();
    TemperatureDisplay();
    BackgroundSwitch();
    Thermoregulation();
    Breathability();
    SoftTouch();
    ModalTrigger();
    VideoModal();

    const hero = Hero();

    hero.enter();

    SectionTracking();
};

export { App };
