import { gsap } from 'gsap';
import { on } from './../../utilities/bigbro';
import {
    CanvasTexture,
    LinearEncoding,
    LinearSRGBColorSpace,
    Mesh,
    MeshBasicMaterial,
    PlaneGeometry,
    SRGBColorSpace,
    ShaderMaterial,
    sRGBEncoding,
} from 'three';
import { EVENTS } from '../events';

const canvas = document.querySelector('.js-lines-canvas');
const PARAMS = {
    particleAmount: 15,
    vibrateAmount: 0,
    line: {
        height: 1,
        width: [10, 100],
    },
    speed: {
        range: [3, 6],
        amount: 5,
    },
};

class Canvas {
    constructor(canvasParent) {
        this.lines = [];
        this.speedMultiplier = 0;
        this.ctx = this.setupCanvas(canvasParent);

        this.init();
    }

    init() {
        this.onResize();
        this.populateLines();
        this.setupEventListeners();
    }

    setupEventListeners() {
        on(EVENTS.SPEED_INCREASE, this.onPointerDown);
        on(EVENTS.SPEED_DECREASE, this.onPointerUp);

        // on(EVENTS.RESIZE, this.onResize);
        gsap.ticker.add(this.onTick);
    }

    setupCanvas(canvas) {
        this.dpr = 1;
        const rect = canvas.getBoundingClientRect();
        canvas.width = 128;
        canvas.height = 128;
        const ctx = canvas.getContext('2d');
        ctx.globalAlpha = 0.1;
        ctx.scale(this.dpr, this.dpr);
        return ctx;
    }

    populateLines() {
        const { random } = gsap.utils;

        for (let i = 0; i < PARAMS.particleAmount; i++) {
            const params = {
                size: random(PARAMS.line.width[0], PARAMS.line.width[1]),
                speed: random(PARAMS.speed.range[0], PARAMS.speed.range[1]),
                x: random(0, this.vW),
                y: random(PARAMS.line.height, this.vH - PARAMS.line.height),
                color: 211,
            };
            this.lines.push(params);
        }
    }

    clear() {
        this.ctx.clearRect(0, 0, this.vW, this.vH);
    }

    drawLine(x, y, oldX, oldY, color) {
        this.ctx.beginPath();
        this.ctx.moveTo(oldX, oldY);
        this.ctx.lineTo(x, y);
        const gradient = this.ctx.createLinearGradient(x, y, oldX, oldY);
        gradient.addColorStop(0, `hsla(${color}, 100%, 56%, 1)`);
        gradient.addColorStop(1, `hsla(${color}, 100%, 56%, .1)`);
        this.ctx.strokeStyle = gradient;
        this.ctx.lineWidth = PARAMS.line.height;
        this.ctx.stroke();
    }

    onPointerDown = () => {
        gsap.to(this, {
            speedMultiplier: 4,
            duration: 2,
            ease: 'sine.in',
        });
    };

    onPointerUp = () => {
        gsap.to(this, {
            speedMultiplier: 0,
            duration: 1,
            ease: 'sine.out',
            overwrite: true,
        });
    };

    onTick = (time) => {
        this.clear();

        const vibrate =
            Math.sin(time) * PARAMS.vibrateAmount * this.speedMultiplier;
        const random = gsap.utils.random(-1, 1);

        for (let i = 0; i < this.lines.length; i++) {
            this.lines[i].x -=
                this.lines[i].speed +
                PARAMS.speed.amount * this.speedMultiplier;
            if (this.lines[i].x <= -this.lines[i].size) {
                this.lines[i].x = this.vW;
            }

            this.drawLine(
                this.lines[i].x,
                this.lines[i].y + vibrate * random,
                this.lines[i].x + this.lines[i].size,
                this.lines[i].y + vibrate * random,
                this.lines[i].color
            );
        }
    };

    onResize = () => {
        this.vW = 512;
        this.vH = 512;

        this.ctx.canvas.style.width = `${this.vW}px`;
        this.ctx.canvas.style.height = `${this.vH}px`;

        this.ctx.canvas.width = this.vW * this.dpr;
        this.ctx.canvas.height = this.vH * this.dpr;

        this.ctx.scale(this.dpr, this.dpr);
    };
}

function Lines() {
    new Canvas(canvas);
    const texture = new CanvasTexture(canvas);
    const planeGeometry = new PlaneGeometry(1, 1);
    const material = new ShaderMaterial({
        transparent: true,
        uniforms: {
            canvasTexture: { value: texture },
        },
        vertexShader: `
            varying vec2 vUv;

            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `,
        fragmentShader: `
            varying vec2 vUv;
            uniform sampler2D canvasTexture;
        
            void main() {
                vec4 texture = texture2D(canvasTexture, vUv);
                vec3 color = vec3(1., 0.,0.);
        
                gl_FragColor = texture;
        
            }
        `,
    });
    const mesh = new Mesh(planeGeometry, material);
    mesh.rotation.y = -Math.PI / 2;

    gsap.ticker.add(() => {
        texture.needsUpdate = true;
        mesh.material.uniforms.canvasTexture.value.needsUpdate = true;
    });

    return mesh;
}

export { Lines };
