import { PerspectiveCamera } from 'three';

function Camera({
    fov = 55,
    aspect = window.innerWidth / window.innerHeight,
    near = 0.1,
    far = 1000,
} = {}) {
    const camera = new PerspectiveCamera(fov, aspect, near, far);

    camera.resize = () => {
        camera.fov = fov;
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
    };

    return camera;
}

export { Camera };
