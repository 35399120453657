const loaders = new Map();
const resources = new Map();
const assets = new Map();

export const addLoader = (...entries) => {
    entries.forEach(({ alias, loader, loadFunction }) => {
        if (loaders.get(alias) === undefined) {
            loaders.set(alias, { loader, loadFunction });
        } else {
            console.log(`loader ${alias} already exists`);
        }
    });
};

export const getLoaders = () => loaders;

export const removeLoader = (alias) => loaders.delete(alias);

export const addResources = (sources) => {
    sources.forEach(({ name, type, url }) => {
        if (resources.get(name) === undefined) {
            resources.set(name, { type, url });
        } else {
            console.log(`A source named ${name} has already been stored`);
        }
    });
};

export const loadAssets = async (
    onSuccess = async () => {},
    onError = async () => {},
    onComplete = async () => {}
) => {
    try {
        await Promise.all(
            [...resources].map(async ([key, { type, url }], index) => {
                try {
                    const loader = loaders.get(type);
                    const data = await loader.loadFunction(loader.loader, url);

                    assets.set(key, data);
                    await onSuccess(assets.size, index, key, data);
                } catch (error) {
                    await onError(index, key, error);
                }
            })
        );
    } catch {
        return false;
    }

    onComplete(assets);
};

export const getAsset = (...list) =>
    list.length < 2
        ? assets.get(list[0])
        : list.map((item) => {
              const element = assets.get(item);

              if (!element) {
                  return false;
              }

              return element;
          });
