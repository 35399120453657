import gsap from 'gsap';
import { emit } from '../utilities/bigbro';
import Component from '../utilities/Component';
import { handle } from '../utilities/virtualizer';
import { EVENTS } from '../webgl/events';

const SpeedButton = Component('.js-speed-btn', (element) => {
    const stroke = element.querySelector('.c-speed-btn__stroke');
    const fill = element.querySelector('.c-speed-btn__back');
    const revealingLines = [
        ...element.querySelectorAll('.c-speed-btn__arrow.should-reveal'),
    ];
    const firstLines = [
        ...element.querySelectorAll('.c-speed-btn__arrow.is-first'),
    ];

    const flickerTl = gsap.timeline({
        paused: true,
        defaults: { ease: 'flicker', duration: 0.25 },
    });

    flickerTl
        .to(fill, {
            opacity: 0,
        })
        .to(fill, {
            opacity: 1,
        });

    const btl = gsap.timeline({ paused: true, defaults: { duration: 1 } });

    btl.fromTo(
        firstLines,
        {
            yPercent: -100,
            transformOrigin: 'center center',
        },
        { yPercent: 0, duration: 1 },
        '<'
    )
        .fromTo(
            revealingLines,
            { opacity: 0 },
            { opacity: 1, ease: 'flicker' },
            '<'
        )
        .to(
            stroke,
            {
                strokeDashoffset: '+=.5',
                autoRound: false,
                scale: 0.6,
                ease: 'power3.inOut',
            },
            '<'
        )
        .fromTo(
            fill,
            { scale: 1 },
            {
                scale: 1.61,
                ease: 'power3.inOut',
            },
            '<'
        );

    const handleSpeedButtonPointerDown = (event) => {
        event.preventDefault();
        btl.timeScale(1);
        btl.play();
        flickerTl.timeScale(1);
        flickerTl.restart();

        emit(EVENTS.SPEED_INCREASE);
    };
    const handleSpeedButtonPointerUp = (event) => {
        event.preventDefault();
        btl.timeScale(3);
        btl.reverse();
        flickerTl.timeScale(3);
        flickerTl.restart();

        emit(EVENTS.SPEED_DECREASE);
    };

    element.addEventListener('touchstart', handleSpeedButtonPointerDown);
    element.addEventListener('touchend', handleSpeedButtonPointerUp);

    element.addEventListener('mousedown', handleSpeedButtonPointerDown);
    element.addEventListener('mouseup', handleSpeedButtonPointerUp);
});

export { SpeedButton };
