const bus = new Map();

/**
 * Add one or more event listeners or register a callback to one or more events
 *
 * @param events the name of one or more events
 * @param domOrCb the dom element or bus callback
 * @param listener the dom event listener
 * @param options the dom event options
 */
const on = (events, domOrCallback, listener, options = false) => {
    events.split(' ').forEach((event) => {
        if (listener && typeof domOrCallback !== 'function') {
            domOrCallback.addEventListener(event, listener, options);
            return;
        }

        if (bus.get(event) === undefined) {
            bus.set(event, new Set()).get(event).add(domOrCallback);
        } else {
            bus.get(event).add(domOrCallback);
        }
    });
};

/**
 * Remove one or more event listeners or detatches a callback from on or more events
 *
 * @param events the name of one or more events
 * @param domOrCb the dom element or bus callback
 * @param listener the dom event listener
 */
const off = (events, domOrCallback, listener) => {
    events
        .split(' ')
        .forEach((event) =>
            listener && typeof domOrCallback !== 'function'
                ? domOrCallback.removeEventListener(event, listener)
                : bus.get(event)?.delete(domOrCallback)
        );
};

/**
 * Emit the desired event
 *
 * @param event the name of the event
 * @param args the arguments that will be passed to the callbacks
 */
const emit = (event, ...arguments_) => {
    bus.get(event)?.forEach((domOrCallback) => domOrCallback(...arguments_));
};

/**
 * Emit the desired event asynchronously
 *
 * @param event the name of the event
 * @param args the arguments that will be passed to the callbacks
 * @returns the promise
 */
const emitAsync = (event, ...arguments_) =>
    Promise.all(
        [...(bus.get(event) || [])].map((domOrCallback) =>
            Promise.resolve(domOrCallback(...arguments_))
        )
    );

/**
 * Remove one or more events from the stack
 *
 * @param events the name of one or more events
 */
const remove = (events) => {
    events.split(' ').forEach((event) => bus.delete(event));
};

/**
 * Generate a clone of the bus stack, usefull for debug
 *
 * @returns a clone of the bus stack
 */
const inspect = () => new Map(bus);

export { on, off, emit, emitAsync, remove, inspect };
