import gsap from 'gsap';
import SplitText from 'gsap/SplitText';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Component from '../utilities/Component';

const SoftTouch = Component('.js-soft-touch', (element) => {
    const tl = gsap.timeline({
        paused: true,
        defaults: { duration: 1 },
    });

    ScrollTrigger.create({
        trigger: element,
        start: 'top top',
        end: 'bottom top',
        onEnter: () => {
            tl.play();
        },
    });

    const videos = [...element.querySelectorAll('.js-soft-touch-video')];

    const title = element.querySelector('.js-soft-touch-title');
    const titleSplit = new SplitText(title, { type: 'words, chars' });

    const copies = [...element.querySelectorAll('.js-soft-touch-copy')];

    copies.forEach((copy) => {
        const copySplit = new SplitText(copy, { type: 'words, chars' });

        tl.fromTo(
            copySplit.chars,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                duration: 0.001,
                stagger: {
                    each: 0.008,
                },
            },
            '<'
        );
    });

    tl.fromTo(
        videos,
        {
            clipPath: 'polygon(0 0, 100% 0, 100% 0%, 0 0%)',
        },
        {
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
            duration: 1.5,
            ease: 'power3.inOut',
        },
        '<'
    ).fromTo(
        titleSplit.chars,
        {
            opacity: 0,
        },
        {
            opacity: 1,
            duration: 0.3,
            stagger: { amount: 0.3, from: 'random' },
            ease: 'flicker',
        },
        '<'
    );
});

export { SoftTouch };
