import { EventDispatcher } from 'three';

const EVENTS = {
    ENABLE: 'webgl:enable',
    RENDER: 'webgl:render',
    AFTER_RENDER: 'webgl:after_render',
    AFTER_RESIZE: 'webgl:after_resize',
    RESIZE: 'app:resize',
    HEAT_ENTER: 'app:heat_enter',
    HEAT_PROGRESS: 'app:heat_progress',
    HEAT_CHANGE: 'app:heat_change',
    SPEED_INCREASE: 'app:speed_increase',
    SPEED_DECREASE: 'app:speed_decrease',
    SCROLL_PROGRESS: 'app:scroll_progress',
    SOUND_TOGGLE: 'app:sound_toggle',
    ASSET_UPDATE: 'app:asset_update',
    ASSET_COMPLETE: 'app:asset_complete',
    LOADER_UPDATE: 'app:loader_update',
    LOADER_COMPLETE: 'app:loader_complete',
    APP_START: 'app:start',
    OPEN_MODAL: 'app:open_modal',
};

const dispatcher = new EventDispatcher();

export { dispatcher, EVENTS };
