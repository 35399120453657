import normalizeWheel from 'normalize-wheel';
import { emit, off as _off, on as _on, inspect } from './bigbro';

const EVENTS = {
    wheel: 'virtualizer:wheel',
    pointerdown: 'virtualizer:pointerdown',
    pointermove: 'virtualizer:pointermove',
    pointerup: 'virtualizer:pointerup',
    drag: 'virtualizer:drag',
    keydown: 'virtualizer:keydown',
    navigation: 'virtualizer:navigation',
};

const defaultConfig = {
    trackPointer: true,
    enableWheel: true,
    enableTouch: true,
    enableDrag: true,
    enableNavigation: true,
    enableKeyboard: true,
    spaceStep: 'window',
    arrowStep: 40,
};

const setup = {
    isActive: false,
    config: defaultConfig,
};

const pointer = {
    x: 0,
    y: 0,
};

const handleWheel = (event) => {
    const normalized = normalizeWheel(event);

    emit(EVENTS.wheel, {
        ...normalized,
        event,
    });
};

const handlePointerDown = (event) => {
    const { clientX, clientY, button, pointerType } = event;
    const { enableDrag, enableTouch } = setup.config;

    const disabledGesture =
        (!enableDrag && pointerType === 'mouse') ||
        (!enableTouch && pointerType === 'touch');

    if (button !== 0 || disabledGesture) return;

    pointer.x = clientX || 0;
    pointer.y = clientY || 0;

    emit(EVENTS.pointerdown, {
        pointerX: pointer.x,
        pointerY: pointer.y,
        type: pointerType,
        event,
    });
};

const handlePointerMove = (event) => {
    const { clientX, clientY, buttons, pointerType } = event;
    const { enableDrag, enableTouch, trackPointer } = setup.config;

    if (trackPointer && pointerType === 'mouse') {
        emit(EVENTS.pointermove, {
            pointerX: clientX || 0,
            pointerY: clientY || 0,
            event,
        });
    }

    const disabledGesture =
        (!enableDrag && pointerType === 'mouse') ||
        (!enableTouch && pointerType === 'touch');

    if (buttons !== 1 || disabledGesture) return;

    event.preventDefault();

    emit(EVENTS.drag, {
        dragX: clientX || 0,
        dragY: clientY || 0,
        type: pointerType,
        event,
    });
};

const handlePointerUp = (event) => {
    const { clientX, clientY, button, pointerType } = event;
    const { enableDrag, enableTouch } = setup.config;

    const disabledGesture =
        (!enableDrag && pointerType === 'mouse') ||
        (!enableTouch && pointerType === 'touch');

    if (button !== 0 || disabledGesture) return;

    pointer.x = clientX || 0;
    pointer.y = clientY || 0;

    emit(EVENTS.pointerup, {
        pointerX: pointer.x,
        pointerY: pointer.y,
        type: pointerType,
        event,
    });
};

const keyCodes = {
    Tab: () => 0,
    ArrowDown: () => setup.config.arrowStep,
    ArrowRight: () => setup.config.arrowStep,
    ArrowUp: () => -setup.config.arrowStep,
    ArrowLeft: () => -setup.config.arrowStep,
    Space: () => window.innerHeight,
    PageDown: () => window.innerHeight,
    PageUp: () => -window.innerHeight,
};

const allowedKeyCodes = new Set([
    'Tab',
    'ArrowDown',
    'ArrowRight',
    'ArrowUp',
    'ArrowLeft',
    'Space',
    'PageDown',
    'PageUp',
]);

const isAllowedKeyCode = (code) =>
    typeof code === 'string' && allowedKeyCodes.has(code);

const handleKeyDown = (event) => {
    const { code, shiftKey } = event;

    if (setup.config.enableNavigation && isAllowedKeyCode(code)) {
        const value =
            code === 'Tab'
                ? 0
                : code === 'Space'
                ? keyCodes[code]() * (shiftKey ? -1 : 1)
                : keyCodes[code]();

        emit(EVENTS.navigation, {
            code,
            shift: shiftKey,
            value,
            event,
        });
    }

    if (setup.config.enableKeyboard) {
        emit(EVENTS.keydown, {
            code,
            shift: shiftKey,
            event,
        });
    }
};

const enable = (config = {}) => {
    if (setup.isActive) {
        return;
    }

    setup.isActive = true;
    setup.config = { ...defaultConfig, ...config };

    if (setup.config.enableWheel) {
        _on('wheel', window, handleWheel);
    }

    if (setup.config.enableTouch || setup.config.enableDrag) {
        _on('pointerdown', window, handlePointerDown);
        _on('pointermove', window, handlePointerMove);
        _on('pointerup', window, handlePointerUp);
    }

    if (setup.config.enableKeyboard) {
        _on('keydown', window, handleKeyDown);
    }
};

const disable = () => {
    setup.isActive = false;

    if (setup.config.enableWheel) {
        _off('wheel', window, handleWheel);
    }

    if (setup.config.enableTouch || setup.config.enableDrag) {
        _off('pointerdown', window, handlePointerDown);
        _off('pointermove', window, handlePointerMove);
        _off('pointerup', window, handlePointerUp);
    }

    if (setup.config.enableKeyboard) {
        _off('keydown', window, handleKeyDown);
    }
};

const handle = (event, callback) => {
    _on(EVENTS[event], callback);
};

const detatch = (event, callback) => {
    _off(EVENTS[event], callback);
};
export { enable, disable, handle, detatch };
