import gsap from 'gsap';

import Component from '../utilities/Component';

const generatePath = (width, height, wide, narrow, stroke = 0) => {
    const offset = stroke / 2;

    return `M${offset} ${wide + offset} V${wide + offset} L${
        wide + offset
    } ${offset} L${width - narrow - offset} ${offset} L${width - offset} ${
        narrow + offset
    } V${height - wide - offset} L${width - wide - offset} ${
        height - offset
    } H${narrow + offset} L${offset} ${height - narrow - offset} Z`;
};

const createSvg = ({
    className,
    width,
    height,
    wideAngle,
    narrowAngle,
    offset,
}) => {
    const namespaceURI = 'http://www.w3.org/2000/svg';

    const svg = document.createElementNS(namespaceURI, 'svg');
    svg.classList.add(className);
    svg.setAttribute('viewBox', `0 0 ${width} ${height}`);

    const path = document.createElementNS(namespaceURI, 'path');
    path.setAttribute(
        'd',
        generatePath(width, height, wideAngle, narrowAngle, offset)
    );
    svg.append(path);

    return svg;
};

const Button = Component('.js-btn', (element) => {
    const { width, height } = element.getBoundingClientRect();

    const fill = createSvg({
        className: 'c-btn__fill',
        width,
        height,
        wideAngle: 14,
        narrowAngle: 5,
        offset: 12,
    });
    const stroke = createSvg({
        className: 'c-btn__stroke',
        width,
        height,
        wideAngle: 16,
        narrowAngle: 7,
        offset: 0.5,
    });

    element.append(fill, stroke);

    element.addEventListener('mouseenter', () => {
        gsap.fromTo(
            fill,
            { opacity: 0 },
            {
                opacity: 1,
                duration: 0.25,
                ease: 'flicker',
            }
        );
        gsap.to(stroke, {
            strokeDashoffset: '-=180',
            ease: 'power3.out',
            duration: 1,
        });
    });
});

export { Button };
